@import "../../base/colors.scss";

.is-active {
  background: $blue !important;
  color: white !important;
  padding: 1rem;
}
.logo {
  width: 15rem;
}
.notify-icon {
  position: relative;
  font-size: 23px;

  .notify-number {
    position: absolute;
    background-color: #11C08F !important;
    width: 23px;
    height: 23px;
    font-size: 16px;
    border-radius: 50%;
    color: #fff;
    padding: 3px ;
  }
}
 html[dir="ltr"] .notify-icon .notify-number {
  right: 40% ;
  bottom: 40% ;
} 


 html[dir="rtl"] .notify-icon .notify-number {
  left: 40% ;
  bottom: 40% ;
} 

​.notification .dropdown-menu.show {
  //background-color: red !important;

}
​ .notify-show-more {
  background-color: #017bff !important;
  font-size: 19px;
  color: #fff;
}
.bg-notifcation{
    background-color: #F4F8FF;
}
.readed_notify{
   background-color: #ececec;
}
.readed_icon{
   font-size: 10px;
}

.box-header span{
   
    font-family: "Cairo" !important;
}