.wallet {
  background-image: url("../../../../assets/images/wallet.png");
  background-position: 0 10em;
  background-repeat: no-repeat;
  background-size: cover;

  .balance {
    height: 400px;
    display: flex;
    align-items: center;

    .current {
      font: normal normal 600 28px/30px Cairo;
      letter-spacing: 0px;
      color: #324356;
    }
    .amount {
      font: normal normal bold 30px/32px Roboto;
      letter-spacing: 0px;
      color: #00b437;
      span {
        font: normal normal normal 24px/25px Cairo;
        letter-spacing: 0px;
        color: #324356;
      }
    }

    .add {
      width: 200px;
      height: 60px;
      background: #00b437 0% 0% no-repeat padding-box;
      border-radius: 5px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .tabs {
    margin: 1rem 0;
    .tab {
      border: 1px solid #c6cbd0;
      border-radius: 5px;
      width: 160px;
      height: 45px;
      font-size: 1.2rem;

      &.active {
        color: #fff;
        background: #0184fe 0% 0% no-repeat padding-box;
      }
    }
  }
}

.filter {
  padding: 0.8em;
  background: #d3d9de;
  > div {
    margin-inline-end: 1rem;
    width: 300px;
  }
  .text_filed {
    height: 40px;
    background: white;
    > div {
      height: 35px;
    }
  }
  .select_field {
    padding: 0 !important;
    height: 35px !important;
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
  .search {
    background: #0184fe;
    border-radius: 4px;
    width: 100px;
    height: 35px;
    color: #fff;
  }

  .reset {
    color: #6e6e6e;
  }
}

.walletBalanceRow {
  display: flex;
  flex-direction: row;
  margin-top: 31px;
}


.walletBalanceContainer {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
}

html[dir="rtl"] .walletBalanceContainer {
    margin-left: 60px;
    margin-right: unset;
  }

.walletBalanceHeader {
  font-size: 30px;
  color: #324356;
  font-family: "Cairo";
  font-weight: 700;
}

.walletBalanceContent {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #dbe6f1;
  padding: 24px;
}

.walletBalanceAmountContent {
  display: flex;
  flex-direction: column;
  background-color: #deeaf3;
  border: 1px solid #dbe6f1;
  border-radius: 10px;
  align-items: center;
  padding: 15px 115px 15px 115px;
}

.walletBalanceAmountTitle {
  font-family: "Cairo";
  font-size: 28px;
  font-weight: 600;
  color: #324356;
  padding: 0px;
  margin: 0px;
}

.walletBalanceAmountNumber {
 
  font-size: 50px;
  font-weight: 400;
  color: #324356;
  padding: 0px;
  margin: 0px;
}

.walletBalanceAmountNumberSpan {
    font-family: "Roboto";
    font-size: 50px;
    font-weight: 600;
    color: #324356;
    padding: 0px;
    margin: 0px;
}

.walletBalanceAmountPound {
  font-family: "Cairo";
  font-size: 24px;
  font-weight: 400;
  color: #324356;
  padding: 0px;
    margin: 0px;
}

.walletBalanceFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
}

.walletBalanceDebtFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 24px;
}

.walletBalanceAdd {
    font-family: "Cairo";
    font-size: 24px;
    font-weight: 700;
    color: #00B437;
}

.walletBalanceAdd:hover {
    color: #00B437 !important;
}

.walletAccepted {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff !important;
    font-size: 16px;
    background-color: #28a745 !important;
    // max-height: 45px;
}

.walletRejected {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff !important;
    font-size: 16px;
    background-color: #AD0000 !important;
    margin-left: 10px;
    // max-height: 45px;
}

html[dir="rtl"] .walletRejected {
    margin-right: 10px;
    margin-left: unset;
}

.walletCheck {
    margin-right: 6px;
}

html[dir="rtl"] .walletCheck {
    margin-left: 6px;
    margin-right: unset;
}


.walletRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.WalletCompanyCard {
  background-color: #fff !important;
  border: 1px solid #EAE8E9 !important;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;
  padding: 0px !important;
  width: 100% !important;
}

.WalletCompanyInfo {
  width: 100%;
  justify-content: center;
  button.back {
      background: #696e72;
      color: #fff;
      border: 1px solid #c6cbd0;
      border-radius: 5px;
      width: 180px;
      height: 40px;
      font-size: 1.2rem;
  }
}