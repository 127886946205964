// Directions

@import "~bootstrap/scss/bootstrap";
@import "base/rtl.scss";

//components files
@import "modules/component/component.scss";

//base files
@import "base/general.scss";
@import "base/colors.scss";

//modules files
@import "modules/auth/all";
@import "modules/companies/companies.scss";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "modules/companies/tripsData.scss";
@import "modules/companies/pcrReservation.scss";
@import "modules/workTeam/workTeam.scss";
@import "modules/workTeam/Collapse.scss";
@import "modules/companies/medicalService.scss";
@import "modules/companies/dashboard.scss";
@import "shared/inputs.scss";
@import "shared/_loader.scss";
@import "responsive.scss";
//shared files
.DateTimePicker {
  border: none !important;
  fieldset {
    border: none !important;
  }
  input {
    padding: 3px 14px !important;
  }
  button {
    margin-bottom: 12px !important;
    padding: 0 !important;
    svg {
      width: 28px !important;
      height: 28px !important;
    }
  }
}
// public

body {
  background-color: #f9f9f9;
}
.table th {
  padding: 0.7rem !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gray !important;
}
.header-icons {
  button {
    display: inline-block;
    .fa-sign-out-alt {
      font-size: 25px;
      color: #007bff;
    }
  }
  .dropdown {
    display: inline-block;
  }
}
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1520px; /** 1320px*/
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.mt-15 {
  margin-top: 15% !important;
}

.image-uploader {
  &__img {
    border-radius: 200px;
    display: block;
    margin: 0 auto;
    width: 13rem;
    height: 13rem;
  }

  &__outline {
    width: 14rem;
    height: 14rem;
    border-radius: 180px;
    padding: 0.4rem;
    border: 1px solid $gray-200;
  }

  &__plus-btn {
    position: absolute;
    z-index: 9999;
    // #{var(--right)}: 0;
    top: 0;
    width: 40px;
    height: 40px;
  }
}

.HomeContainer {
  display: flex;
  flex-direction: row;
}

.HomeCompanyContainer {
  display: flex;
  width: 25%;
  flex-direction: column;
}

.HomeCompanyCount {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #b4b4b429;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 30px;
}

.HomeCompanyHeader {
  margin-bottom: 41px;
}

.HomeCompanyHeaderTitle {
  font-size: 1rem;
  color: #5a5a5a;
  font-weight: bold;
}

.HomeCompanyIcon {
  color: #24aefb;
  font-size: 53px;
}

.HomeCompanyNumber {
/*   font-family: "Proxima Nova"; */
  font-size: 30px;
  color: #5a5a5a;
  font-weight: 600;
}

.HomeCompanyContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.HomeCompanyLine {
  height: 2px;
  background-color: #24aefb;
  width: 36px;
}

.HomeUsersContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 16px;
  margin-right: 16px;
}

.HomeUsersChar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #b4b4b429;
  border-radius: 3px;
  padding: 20px;
  width: 100%;
}

.HomeUsersHeader {
  margin-bottom: 41px;
}

.HomeUsersHeaderTitle {
  font-size: 1rem;
  color: #5a5a5a;
  font-weight: bold;
}

.HomeUsersContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.HomeUserStatistics {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.HomeUserStatisticsTitle {
  font-size: 1.5rem;
  color: #5a5a5a;
  font-weight: 400;
}

.HomeUserStatisticsCount {
  font-size: 30px;
  color: #5a5a5a;
  font-weight: bold;
}

.HomeUserCount {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomeUserCountRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 31px;
}

.HomeUserCountIncomingIcon {
  color: #24aefb;
}

.HomeUserCountRunningIcon {
  color: #f28f31;
}

.HomeUserCountCheckedIcon {
  color: #00b437;
}

.HomeUserCountContent {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
}

html[dir="rtl"] .HomeUserCountContent {
  margin-left: unset;
  margin-right: 14px;
}

.HomeUserCountContentTitle {
  color: #2d2f39;
  font-size: 1.5rem;
/*   font-weight: 600; */
}

.HomeUserCountContentNumber {
  color: #2d2f39;
  font-size: 22px;
  font-weight: 600;
}

.HomeLogContainer {
  display: flex;
  width: 35%;
}

.HomeLogContent {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #b4b4b429;
  border-radius: 3px;
  padding: 20px;
  width: 80%;
}

.HomeLogHeader {
}

.HomeLogHeaderTitle {
  color: #2d2f39;
  font-size: 18px;
  font-weight: 600;
}

.HomeLogHeaderSubTitle {
  color: #4d4f5c;
  font-size: 12px;
  font-weight: 400;
}

.HomeLoginContent {
}

.TimelineDate {
  font-family: "Cairo" !important;
  color: #4d4f5c !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.TimeLineLoginTitle {
  font-family: "Cairo" !important;
  color: #24aefb !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.TimeLineLocationTitle {
  font-family: "Cairo" !important;
  /* color: #4d4f5c !important; */
  font-size: 12px !important;
  font-weight: 400 !important;
}

.TimeLineIPTitle {
  font-family: "Cairo" !important;
/*   color: #4d4f5c !important; */
  font-size: 12px !important;
  font-weight: 400 !important;
}

.HomeLoginContent ul li .MuiTimelineOppositeContent-root {
  max-width: 90px !important;
}

.HomeTimeLineDayContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.HomeTimeLineDay {
  background-color: #f2f2f2;
  border: 1px solid #c6cbd0;
  border-radius: 5px;
  color: #b1b1b1;
  font-size: 14px;
  font-weight: 400;
  width: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.TimeLineDote {
  color: rgb(34, 18, 18) !important;
  border-color: #264ff9 !important;
  background-color: #fff !important;
  border-width: 1px !important;
}

.TimeLineLine {
  background-color: #e9e9f0 !important;
}

.Header-Setting-img {
  width: 36px;
  height: 36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: unset; /** 1320px*/
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.registerFix-layout .password .eye {
  position: absolute;
  left: 2rem;
  top: 2.3rem;
  right: unset;
}
