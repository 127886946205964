.not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 90vh;
	&__image {
		max-height: 50vh;
	}

	&__content {
		margin-top: -3rem;
		max-width: 350px;
		text-align: center;
		.body {
			font-size: 12px;
		}
	}
	&__btn {
		width: 200px;
	}
}
