@media only screen and (min-width: 0px) and (max-width: 575px) {
  :root {
    font-size: 14px !important;
  }
  input {
    font-size: 15px !important;
  }
  .list_content {
    padding: 0rem 0.5rem;
  }
  .font-mobile {
    font-size: 15px !important;
  }
  .text-mobile__center {
    text-align: center;
  }
  html[dir="ltr"] .drop-down__notificatios {
    left: -30% !important;
  }

  html[dir="rtl"] .drop-down__notificatios {
    width: 70%;
    left: 50% !important;
    right: 50% !important;
  }

  .mobile-remove {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .container {
    max-width: 1520px; /** 1320px*/
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .wallet_table {
    background-color: #edf2f7;
  }
  .to {
    position: relative;
    z-index: 1;
  }
  .all-boxs {
    .boxs {
      width: 100%;
    }
  }

  th {
    white-space: nowrap;
  }
  td {
    white-space: nowrap;
  }


  .MobileHeader {
    background-color: #324356 !important;
  }
  .AmountNumber {
    font-size: 17px !important;
    font-weight: 400;
  }
}
