.instructions {
  background-color: #EDF2F7;

}
.text-color {
  color: #656565;
}
.card {
  border: 0px solid #ccd5db;
  border-top: 0px;
  border-radius: 0px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.bg-brwon{
  background-color: #D6D6D6;
}

.group-login .modal-content{
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    width: 135% !important;
    pointer-events: auto !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0.3rem !important; 
    outline: 0 !important;
}
.traveler-login .modal-content{
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 205% !important;
  pointer-events: auto !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem !important; 
  outline: 0 !important;
  left: 50%;
}

.login-thead{
  tr{
    th{
      font-size: 14px !important;
    }
    height: 35px;
    font-family:"cairo";
    text-align: center;


  }
  input[type="checkbox"]{
   
    //display:block;
    background: #808080 !important;
    width: 17px;
    height: 17px;
    border: 1px solid #808080;
  }
}
.loginApi small, .small{
  font-size: 80%;
    font-weight: 400;
    display: block;
}